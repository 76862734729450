import { useCallback, useEffect, useState } from 'react'
import { FetchResult } from '@apollo/client/link/core'
import useCamelMutation from '../../../hooks/useCamelMutation'
import {
  CustomerSettingsPractitionerFragment,
  PutCustomerSettingsPractitionerDocument,
  PutCustomerSettingsPractitionerMutation,
  PutCustomerSettingsPractitionerMutationVariables,
} from '../../../graphql/camel'

export type UsePractitionerSettingsSave = [
  savePractitionerSettings: (
    inputData: PutCustomerSettingsPractitionerMutationVariables['customerSettingsPractitionerUpdateInput']
  ) => Promise<FetchResult<PutCustomerSettingsPractitionerMutation>>,
  alertState: AlertState & {
    dismissAlert: () => void
  },
  mutationState: { isSaving: boolean }
]

export interface UsePractitionerSettingsSaveOptions {
  onUpdateState?: (state: UsePractitionerSettingsUpdateState | undefined) => void
}

export type UsePractitionerSettingsUpdateState = CustomerSettingsPractitionerFragment

export interface AlertState {
  showAlert: boolean
  alertType: 'default' | 'success' | 'error'
  alertMessage: string
}

const initialAlert: AlertState = { showAlert: false, alertType: 'default', alertMessage: '' }

export const usePractitionerSettingsSave = ({
  onUpdateState,
}: UsePractitionerSettingsSaveOptions): UsePractitionerSettingsSave => {
  const [
    putCustomerSettingsPractitioner,
    { data: saveResponse, loading: isSaving, error: saveError },
  ] = useCamelMutation<
    PutCustomerSettingsPractitionerMutation,
    PutCustomerSettingsPractitionerMutationVariables
  >(PutCustomerSettingsPractitionerDocument)

  const [{ showAlert, alertType, alertMessage }, setAlert] = useState(initialAlert)
  useEffect(() => {
    if (!isSaving) {
      if (saveResponse && !saveError) {
        setAlert({
          showAlert: true,
          alertType: 'success',
          alertMessage: 'Settings saved successfully.',
        })
        if (onUpdateState) {
          const updatedPractitionerSettings =
            saveResponse?.putCustomerSettingsPractitioner?.practitioner
          if (updatedPractitionerSettings) {
            onUpdateState(updatedPractitionerSettings)
          }
        }
      } else if (saveError) {
        setAlert({
          showAlert: true,
          alertType: 'error',
          alertMessage: 'We were unable to save your settings changes, please try again soon.',
        })
      }
    }
  }, [isSaving, saveError, saveResponse, onUpdateState])

  const savePractitionerSettings = useCallback(
    (
      inputData: PutCustomerSettingsPractitionerMutationVariables['customerSettingsPractitionerUpdateInput']
    ) => {
      return putCustomerSettingsPractitioner({
        variables: {
          customerSettingsPractitionerUpdateInput: inputData,
        },
      })
    },
    [putCustomerSettingsPractitioner]
  )

  const dismissAlert = useCallback(() => {
    setAlert(initialAlert)
  }, [setAlert])

  return [
    savePractitionerSettings,
    { showAlert, alertType, alertMessage, dismissAlert },
    { isSaving },
  ]
}
