import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'

import { Dispensary, GetDispensaryByCodeDocument } from '../../../graphql/camel'

export interface UseDispensaryByCodeEagerOptions {
  practitionerCode: string | undefined
  skip?: boolean
}

export type UseDispensaryByCodeEager = {
  loading: boolean
  data: Dispensary | null
  error?: ApolloError | Error
  called: boolean
}

export const useDispensaryByCodeEager = ({
  practitionerCode = '',
  skip = false,
}: UseDispensaryByCodeEagerOptions): UseDispensaryByCodeEager => {
  const { loading, data: responseData, error, called } = useQuery(GetDispensaryByCodeDocument, {
    context: {
      uri: process.env.GATSBY_CAMEL_URL,
    },
    skip: skip || !practitionerCode,
    variables: { practitionerCode },
  })

  const data = responseData?.getDispensaryByCode || null

  return {
    loading,
    data,
    error,
    called,
  }
}
