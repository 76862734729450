import {
  ApolloError,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationTuple,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client'
import { DocumentNode } from 'graphql'
import { useCallback, useState } from 'react'
import { useAuth } from '../lib/auth'
import { useCustomerId } from './useCustomerId'

type OmitProvided<TVariables> = Omit<TVariables, 'customerId'>

const useCamelMutation = <TData = unknown, TVariables = Record<string, unknown>>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  hookOptions?: MutationHookOptions<TData, TVariables>
): [
  MutationTuple<TData, OmitProvided<TVariables>>[0],
  Omit<MutationTuple<TData, OmitProvided<TVariables>>[1], 'error'> & { error?: ApolloError | Error }
] => {
  const { getAccessTokenSilently } = useAuth()
  const customerId = useCustomerId()
  const [tokenError, setTokenError] = useState<Error | null>(null)
  const [doMutation, { error: queryError, ...mutationResultProps }] = useMutation(
    mutation,
    (hookOptions as unknown) as MutationHookOptions<TData, TVariables>
  )

  const doCamelMutation = useCallback(
    async (options: MutationFunctionOptions<TData, OmitProvided<TVariables>> = {}) => {
      setTokenError(null)
      try {
        const accessToken = await getAccessTokenSilently()
        return await doMutation({
          ...options,
          // Although the added `customerId` variable will be returned, there's no need to indicate
          // it's addition to the return type. We cast the variables type to the generic parameter
          // instead to maintain expected return type.
          variables: ({
            ...options.variables,
            customerId,
          } as unknown) as TVariables,
          context: {
            ...options.context,
            uri: process.env.GATSBY_CAMEL_URL,
            token: accessToken,
          },
        })
      } catch (accessTokenError) {
        setTokenError(
          accessTokenError instanceof Error ? accessTokenError : new Error(`${accessTokenError}`)
        )
        return {}
      }
    },
    [customerId, doMutation, getAccessTokenSilently]
  )

  return [doCamelMutation, { ...mutationResultProps, error: tokenError || queryError }]
}

export default useCamelMutation
