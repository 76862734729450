import { catchError, filter, switchMap, take } from 'rxjs'
import { fromFetch } from 'rxjs/fetch'

import { headers } from '../../lib/fetch'
import { isNotNull } from '../../utils/collectionTools'
import { coerceCaughtToLeft } from '../../utils/rx/errors'
import { latestAccessToken$ } from '../auth/state'
import { fetchResponseToJsonResult } from '../fetch'

export const fromUpdateUserDispensaryRequest = ({
  practitionerCode,
}: {
  practitionerCode: string
}) =>
  latestAccessToken$.pipe(
    filter(isNotNull),
    take(1),
    switchMap((accessToken) =>
      fromFetch('/api/user/dispensaries', {
        body: JSON.stringify({
          data: {
            practitionerCode,
          },
        }),
        method: 'PUT',
        headers: {
          ...headers.acceptJson,
          ...headers.authorization(accessToken),
          ...headers.contentTypeJson,
        },
      })
    ),
    switchMap(fetchResponseToJsonResult),
    catchError(coerceCaughtToLeft)
  )
