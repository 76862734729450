import { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import type { UseFormMethods } from 'react-hook-form/dist/types/form'

import type {
  PractitionerSettings,
  PractitionerSettingsDataToSave,
  PractitionerSettingsFormFields,
  PractitionerSettingsPractitioner,
} from '../types'
import {
  getDispensaryEmailsDefaultValues,
  getDispensaryEmailsToSave,
  getDispensingOptionsDefaultValues,
  getDispensingOptionsToSave,
  getInboundPatientDefaultValues,
  getInboundPatientToSave,
  getRebateDefaultValues,
  getRebateToSave,
} from '../utils'

export type UsePractitionerSettingsForm = [
  dataHandlers: {
    defaultValues: PractitionerSettingsFormFields
    dataToSave: PractitionerSettingsDataToSave | undefined
    updateSettingsState: (updatedSettings: PractitionerSettingsPractitioner | undefined) => void
    resetFormValues: () => void
  },
  formHandlers: Pick<UseFormMethods, 'control' | 'register' | 'watch' | 'setValue' | 'handleSubmit'>
]

export const usePractitionerSettingsForm = (
  initialSettings: PractitionerSettings
): UsePractitionerSettingsForm => {
  const [settings, setSettings] = useState<PractitionerSettingsPractitioner>(
    initialSettings.practitioner
  )

  const defaultValues = useMemo(() => {
    return {
      ...getDispensingOptionsDefaultValues(settings.dispensingOptions),
      ...getRebateDefaultValues(settings),
      ...getInboundPatientDefaultValues(settings),
      ...getDispensaryEmailsDefaultValues(settings),
    }
  }, [settings])

  const { control, register, watch, setValue, handleSubmit } = useForm<
    PractitionerSettingsFormFields
  >({ defaultValues })
  const formValues = watch()

  const dataToSave = useMemo((): PractitionerSettingsDataToSave | undefined => {
    const toSave = {
      ...getDispensingOptionsToSave(settings.dispensingOptions, formValues),
      ...getRebateToSave(settings, formValues),
      ...getInboundPatientToSave(settings, formValues),
      ...getDispensaryEmailsToSave(settings, formValues),
    }
    return Object.keys(toSave).length > 0 ? toSave : undefined
  }, [settings, formValues])

  const updateSettingsState = useCallback(
    (updatedSettings: PractitionerSettingsPractitioner | undefined) => {
      if (updatedSettings) {
        setSettings(updatedSettings)
      }
    },
    [setSettings]
  )

  const resetFormValues = useCallback(() => {
    ;(Object.keys(defaultValues) as (keyof PractitionerSettingsFormFields)[]).forEach(
      (fieldName) => {
        const value = defaultValues[fieldName]
        setValue(fieldName, value)
      }
    )
  }, [defaultValues, setValue])

  return [
    { defaultValues, dataToSave, updateSettingsState, resetFormValues },
    { control, register, watch, setValue, handleSubmit },
  ]
}
