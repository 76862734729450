import { Alert, ButtonLink, PrimaryButton, Toggle } from '@designsforhealth/dfh-react-components'
import React, { FC, ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { usePractitionerSettingsForm } from '../../../../../lib/practitioner-settings/hooks/usePractitionerSettingsForm'
import { usePractitionerSettingsSave } from '../../../../../lib/practitioner-settings/hooks/usePractitionerSettingsSave'
import { PractitionerSettings } from '../../../../../lib/practitioner-settings/types'

export interface RebateProgramProps {
  children: ReactNode
  settings: PractitionerSettings
}

const Container = styled.div`
  p {
    font-size: 14px;
    line-height: 16px;
  }
  .alert-wrapper {
    scroll-margin-top: 80px;
  }
  .submit-or-cancel {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 29px;
    padding-right: 15px;
    button {
      max-width: 220px;
      flex: 1 0 0%;
    }
  }
`

const RebateProgram: FC<RebateProgramProps> = ({ children, settings }) => {
  const alertRef = useRef<HTMLDivElement>(null)

  const [
    { defaultValues, dataToSave, updateSettingsState, resetFormValues },
    { control, register, handleSubmit },
  ] = usePractitionerSettingsForm(settings)

  const [
    savePractitionerSettings,
    { showAlert, alertType, alertMessage, dismissAlert },
    { isSaving },
  ] = usePractitionerSettingsSave({
    onUpdateState: updateSettingsState,
  })

  const enableSubmit = isSaving ? false : !!dataToSave

  const onSubmit = async () => {
    if (!enableSubmit || !dataToSave) {
      return
    }
    dismissAlert()
    await savePractitionerSettings(dataToSave)
  }

  const onCancel = () => {
    dismissAlert()
    resetFormValues()
  }

  useEffect(() => {
    if (showAlert && alertRef.current) {
      alertRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [showAlert])

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="alert-wrapper" ref={alertRef}>
          {showAlert && (
            <Alert type={alertType} onDismiss={dismissAlert}>
              {alertMessage}
            </Alert>
          )}
        </div>
        <Toggle
          id="enrolledInRebate"
          name="enrolledInRebate"
          register={register}
          control={control}
          defaultChecked={defaultValues.enrolledInRebate}
        >
          {children}
        </Toggle>
        <div className="submit-or-cancel">
          <PrimaryButton
            type="submit"
            text={isSaving ? 'Saving...' : 'Save'}
            disabled={!enableSubmit}
          />
          <ButtonLink text="Cancel" onClick={onCancel} />
        </div>
      </form>
    </Container>
  )
}

export default RebateProgram
