import { useMemo } from 'react'

import { UseSiteSettings } from '../../../hooks/useSiteSettings'
import {
  buildDocumentsOnFile,
  DocumentsNeeded,
  isRequiredForCommissionsAndNotReceived,
  isRequiredForCommissionsAndReceived,
  SettingsDocumentsOnFile,
} from '../utils'

export const usePractitionerDocumentsNeeded = (
  settings: SettingsDocumentsOnFile,
  siteSettings: Pick<UseSiteSettings, 'eftForm' | 'taxIdForm'>
): DocumentsNeeded => {
  const documentsOnFileFrom = useMemo(() => buildDocumentsOnFile(siteSettings ?? {}), [
    siteSettings,
  ])

  const documentsOnFile = useMemo(() => documentsOnFileFrom(settings), [
    documentsOnFileFrom,
    settings,
  ])

  return useMemo((): DocumentsNeeded => {
    const docsOnFile = documentsOnFile ? Array.from(documentsOnFile.entries()) : []

    return {
      neededForCommissions: docsOnFile.filter(isRequiredForCommissionsAndNotReceived),
      receivedForCommissions: docsOnFile.filter(isRequiredForCommissionsAndReceived),
    }
  }, [documentsOnFile])
}
